export const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
    alert: [],
  },
  getters: {
    newAlert: state => state.alert,
    alertSuccess: state => {
      if (state.type === "success") {
        return state.message;
      }
    },
    alertError: state => {
      if (state.type === "error") {
        return state.message;
      }
    },
    alertWarning: state => {
      if (state.type === "warning") {
        return state.message;
      }
    },
  },

  actions: {
    newAlert({ commit }, payload) {
      commit("newAlert", payload);
      return true;
    },
    success({ commit }, message) {
      commit("success", message);
      return true;
    },
    error({ commit }, message) {
      commit("error", message);
      return true;
    },
    warning({ commit }, message) {
      commit("warning", message);
      return true;
    },
    clear({ commit }) {
      commit("clear");
      return true;
    },
    fade({ commit }) {
      commit("fade");
      return true;
    },
  },

  mutations: {
    newAlert(state, { type, message }) {
      state.alert.push({ type, message });
    },
    success(state, message) {
      state.type = "success";
      state.message = message;
    },
    error(state, message) {
      state.type = "error";
      state.message = message;
    },
    warning(state, message) {
      state.type = "warning";
      state.message = message;
    },
    clear(state) {
      state.type = null;
      state.message = null;
      state.alert.shift();
    },
    fade(state) {
      // state.type = null;
      // state.message = null;
      // const interval = setInterval(() => {
      //   state.alert.shift();
      //   if (state.alert.length === 0) {
      //     clearInterval(interval);
      //   }
      // }, 500);
    },
    newAlertClear(state) {
      // const interval = setInterval(() => {
      //   state.alert.shift();
      // }, 500);
      // if (state.alert.length === 0) {
      //   clearInterval(interval);
      // }
    },
    newAlertFade(state) {
      // const interval = setInterval(() => {
      //   state.alert.shift();
      // }, 500);
      // if (state.alert.length === 0) {
      //   clearInterval(interval);
      // }
    },
  },
};
