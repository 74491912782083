export const wizard = {
  namespaced: true,
  state: {
    selectedCategories: [],
    selectedSubCategories: [],
    categories: [],
    subCategories: [],
    countries: [],
    selectedCountries: [],
  },
  getters: {
    selectedCategories: state => state.selectedCategories,
    categories: state => state.categories,
    selectedCountries: state => state.selectedCountries,
    countries: state => state.countries,
    selectedSubCategories: state => state.selectedSubCategories,
    subCategories: state => state.subCategories,
  },

  actions: {
    updateCategories({ commit }, categories) {
      commit("updateCategories", categories);
      return true;
    },
    setMainCategories({ commit }, categories) {
      commit("setMainCategories", categories);
      return true;
    },
    updateShipTo({ commit }, countries) {
      commit("updateShipTo", countries);
      return true;
    },
    setMainShipTo({ commit }, countries) {
      commit("setMainShipTo", countries);
      return true;
    },
    updateSubCategories({ commit }, subCategories) {
      commit("updateSubCategories", subCategories);
      return true;
    },
    setMainSubCategories({ commit }, subCategories) {
      commit("setMainSubCategories", subCategories);
      return true;
    },
  },

  mutations: {
    updateShipTo(state, countries) {
      state.selectedCountries = countries;
    },
    setMainShipTo(state, countries) {
      state.countries = countries;
    },
    updateCategories(state, categories) {
      state.selectedCategories = categories;
    },
    setMainCategories(state, categories) {
      state.categories = categories;
    },
    updateSubCategories(state, subCategories) {
      state.selectedSubCategories = subCategories;
    },
    setMainSubCategories(state, subCategories) {
      state.subCategories = subCategories;
    },
  },
};
