export const global = {
  namespaced: true,
  state: {
    global_host: "",
    global_user: "",
    global_email: "",
    global_currency_iso: "",
    global_shop_id: "",
    global_platform_name: "",
    global_timestamp: "",
    global_state: "",
    global_hmac: "",
    global_role: "",
    global_shopifyToken: "",
    global_host_test: "",
    global_iframe_host: "",
  },
  getters: {
    getGlobalHost: (state) => state.global_host,
    getGlobalUser: (state) => () => {
      return state.global_user;
    },
    getGlobalEmail: (state) => state.global_email,
    getGlobalCurrencyISO: (state) => state.global_currency_iso,
    getGlobalShopId: (state) => state.global_shop_id,
    getGlobalPlatformName: (state) => state.global_platform_name,
    getGlobalTimestamp: (state) => state.global_timestamp,
    getGlobalState: (state) => state.global_state,
    getGlobalHmac: (state) => state.global_hmac,
    getGlobalRole: (state) => state.global_role,
    getGlobalShopifyToken: (state) => state.global_shopifyToken,
    getGlobalHostTest: (state) => state.global_host_test,
    getGlobalIframeHost: (state) => state.global_iframe_host,
  },
  actions: {
    setGlobalHost({ commit }, host) {
      commit("UPDATE_GLOBAL_HOST", host);
    },
    setGlobalUser({ commit }, user) {
      commit("UPDATE_GLOBAL_USER", user);
    },
    removeGlobalUser({ commit }) {
      commit("UPDATE_GLOBAL_USER", null);
    },
    setGlobalEmail({ commit }, email) {
      commit("UPDATE_GLOBAL_EMAIL", email);
    },
    removeGlobalEmail({ commit }) {
      commit("UPDATE_GLOBAL_EMAIL", null);
    },
    setGlobalCurrencyISO({ commit }, currency_iso) {
      commit("UPDATE_GLOBAL_CURRENCY_ISO", currency_iso);
    },
    removeGlobalCurrencyISO({ commit }) {
      commit("UPDATE_GLOBAL_CURRENCY_ISO ", null);
    },
    setGlobalShopId({ commit }, shop_id) {
      commit("UPDATE_GLOBAL_SHOP_ID", shop_id);
    },
    removeGlobalShopId({ commit }) {
      commit("  UPDATE_GLOBAL_SHOP_ID ", null);
    },
    setGlobalPlatformName({ commit }, platform_name) {
      commit("UPDATE_GLOBAL_PLATFORM_NAME", platform_name);
    },
    removeGlobalPlatformName({ commit }) {
      commit("UPDATE_GLOBAL_PLATFORM_NAME", null);
    },
    setGlobalTimestamp({ commit }, timestamp) {
      commit("UPDATE_GLOBAL_TIMESTAMP", timestamp);
    },
    removeGlobalTimestamp({ commit }) {
      commit("UPDATE_GLOBAL_TIMESTAMP", null);
    },
    setGlobalState({ commit }, state_var) {
      commit("UPDATE_GLOBAL_STATE", state_var);
    },
    removeGlobalState({ commit }) {
      commit("UPDATE_GLOBAL_STATE", null);
    },
    setGlobalHmac({ commit }, hmac) {
      commit("UPDATE_GLOBAL_HMAC", hmac);
    },
    removeGlobalHmac({ commit }) {
      commit("UPDATE_GLOBAL_HMAC", null);
    },
    setGlobalRole({ commit }, role) {
      commit("UPDATE_GLOBAL_ROLE", role);
    },
    removeGlobalRole({ commit }) {
      commit("UPDATE_GLOBAL_ROLE", null);
    },
    setGlobalShopifyToken({ commit }, shopifyToken) {
      commit("UPDATE_GLOBAL_SHOPIFY_TOKEN", shopifyToken);
    },
    removeGlobalShopifyToken({ commit }) {
      commit("UPDATE_GLOBAL_SHOPIFY_TOKEN", null);
    },
    setGlobalHostTest({ commit }, host_test) {
      commit("UPDATE_GLOBAL_HOST_TEST", host_test);
    },
    removeGlobalHostTest({ commit }) {
      commit("UPDATE_GLOBAL_HOST_TEST", null);
    },
    setGlobalIframeHost({ commit }, iframe_host) {
      commit("UPDATE_GLOBAL_IFRAME_HOST", iframe_host);
    },
    removeGlobalIframeHost({ commit }) {
      commit("UPDATE_GLOBAL_IFRAME_HOST", null);
    },
  },
  mutations: {
    UPDATE_GLOBAL_HOST(state, host) {
      state.global_host = host;
    },
    UPDATE_GLOBAL_USER(state, user) {
      state.global_user = user;
    },
    UPDATE_GLOBAL_EMAIL(state, email) {
      state.global_email = email;
    },
    UPDATE_GLOBAL_CURRENCY_ISO(state, currency_iso) {
      state.global_currency_iso = currency_iso;
    },
    UPDATE_GLOBAL_SHOP_ID(state, shop_id) {
      state.global_shop_id = shop_id;
    },
    UPDATE_GLOBAL_PLATFORM_NAME(state, platform_name) {
      state.global_platform_name = platform_name;
    },
    UPDATE_GLOBAL_TIMESTAMP(state, timestamp) {
      state.global_timestamp = timestamp;
    },
    UPDATE_GLOBAL_STATE(state, state_var) {
      state.global_state = state_var;
    },
    UPDATE_GLOBAL_ROLE(state, role) {
      state.global_role = role;
    },
    UPDATE_GLOBAL_SHOPIFY_TOKEN(state, shopifyToken) {
      state.global_shopifyToken = shopifyToken;
    },
    UPDATE_GLOBAL_HOST_TEST(state, host_test) {
      state.global_host_test = host_test;
    },
    UPDATE_GLOBAL_IFRAME_HOST(state, iframe_host) {
      state.global_iframe_host = iframe_host;
    },
  },
};
