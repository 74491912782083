import api from "./Api";
import TokenService from "./TokenService";
import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
import authHeader from "@/services/AuthHeader";
class AuthService {
  login({ DeviceId, email, password, userType, service }) {
    return api
      .post("/auth/login/" + userType + "/" + service, {
        DeviceId,
        email,
        password,
      })
      .then((response) => {
        if (response.data.data.accessToken) {
          TokenService.setUser(response.data.data);
          setVariable("email", email);
        }
        return response.data.data;
      });
  }

  logout() {
    TokenService.removeUser();
    removeVariable("email");
  }
  register({ DeviceId, email, password, userType, service }) {
    return api
      .post("/auth/register/" + userType + "/" + service, {
        DeviceId,
        email,
        password,
      })
      .then((response) => {
        setVariable("email", email);
        if (response.data.data.accessToken) {
          TokenService.setUser(response.data.data);
        }

        return response.data.data;
      });
  }
  async requestForgotPassword(payload) {
    return api.post("/auth/reset_pass/start", payload).then((response) => {
      return response;
    });
  }
  async resetPassword(payload) {
    return api.post("/auth/reset_pass", payload).then((response) => {
      return response;
    });
  }

  async getInitialState() {
    try {
      const response = await api.get("/sellers/status/current", {
        headers: authHeader(),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async forwardOnboarding(step) {
    try {
      const response = await api.post("retailers/onboarding/" + step, {
        headers: authHeader(),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getRetailerInitialState(accessToken) {
    try {
      const response = await api.get("/retailers/status/current", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Source: process.env.VUE_APP_SOURCE_RETAILER,
          "Source-Name": process.env.VUE_APP_SOURCE_NAME_RETAILER,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async sellerAssignUser({
    email,
    DeviceId,
    password,
    action,
    accesToken,
    source,
    sourceName,
  }) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_BASEURL}/sellers/users/assign/${action}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accesToken}`,
            Source: source,
            "Source-Name": sourceName,
          },
          body: JSON.stringify({
            email,
            password,
            DeviceId,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async retailerAssignUser({
    email,
    DeviceId,
    password,
    action,
    accesToken,
    source,
    sourceName,
  }) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_BASEURL}/retailers/users/assign/${action}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accesToken}`,
            Source: source,
            "Source-Name": sourceName,
          },
          body: JSON.stringify({
            email,
            password,
            DeviceId,
          }),
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async sellerUnAssignUser() {
    try {
      const response = await api.post("/sellers/users/unassign", {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async retailerUnAssignUser(accesToken) {
    try {
      const response = await api.post("/retailers/users/unassign", {
        headers: {
          Authorization: `Bearer ${accesToken}`,
          Source: process.env.VUE_APP_SOURCE_RETAILER,
          "Source-Name": process.env.VUE_APP_SOURCE_NAME_RETAILER,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new AuthService();
