<template>
  <v-app dark>
    <div v-if="newAlert.length && newAlert[0].type" class="notification_toast">
      <TransitionGroup
        name="slide-fade"
        :duration="{ enter: 500, leave: 800 }"
        appear
      >
        <div v-for="(alert, index) in newAlert" :key="index">
          <keep-alive>
            <Notification :notifType="alert.type">
              {{ alert.message }}
            </Notification>
          </keep-alive>
        </div>
      </TransitionGroup>
    </div>

    <div id="app">
      <header ref="header" class="navigation" v-if="checkRouteForLandingHeader">
        <div class="hidden-on-mobile">
          <div class="nav_container">
            <div class="row py-4">
              <div class="col-md-12">
                <div>
                  <div id="nav">
                    <router-link to="/">
                      <img
                        src="@/assets/img/logo-header.svg"
                        alt="Vgang logo"
                        class="logo"
                      />
                    </router-link>
                    <div class="navigations">
                      <buttonLink
                        :content="'Dropshippers'"
                        :destination="'/'"
                        :isInternal="false"
                        :navigation="false"
                      >
                      </buttonLink>
                      <buttonLink
                        :content="'Pricing'"
                        :destination="'/pricing'"
                        :isInternal="false"
                        :navigation="false"
                      >
                      </buttonLink>
                      <buttonLink
                        :content="'Sell on VGANG'"
                        :destination="'/partnership'"
                        :isInternal="false"
                        :navigation="false"
                      >
                      </buttonLink>

                      <buttonLink
                        :content="'Affiliates'"
                        :destination="'/affiliates'"
                        :isInternal="false"
                        :navigation="false"
                      >
                      </buttonLink>
                    </div>
                    <div class="buttons-container">
                      <button-link
                        class="login-btn"
                        :border="true"
                        :isInternal="true"
                        v-if="!userIsLoggedIn"
                        :content="'Login for dropshippers'"
                        :destination="'/login/retailer'"
                        :login="true"
                        :outlined="true"
                      >
                      </button-link>

                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'Pricing' &&
                          userIsLoggedIn &&
                          userType == 'supplier'
                        "
                        :content="' Go To Dashboard'"
                        :destination="'/supplier'"
                        :outlined="true"
                      >
                      </button-link>
                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'Pricing' &&
                          userIsLoggedIn &&
                          userType == 'retailer'
                        "
                        :content="' Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                      >
                      </button-link>

                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'AboutUs' &&
                          userIsLoggedIn &&
                          userType == 'supplier'
                        "
                        :content="' Go To Dashboard'"
                        :destination="'/supplier'"
                        :outlined="true"
                      >
                      </button-link>
                      <button-link
                        :isInternal="true"
                        v-if="$route.name == 'Affiliates' && userIsLoggedIn"
                        :content="' Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                      >
                      </button-link>
                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'AboutUs' &&
                          userIsLoggedIn &&
                          userType == 'retailer'
                        "
                        :content="' Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                      >
                      </button-link>

                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'Home' &&
                          userIsLoggedIn &&
                          userType == 'retailer'
                        "
                        :content="' Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                        :login="true"
                      >
                      </button-link>
                      <button-link
                        :isInternal="true"
                        v-if="
                          $route.name == 'Partnership' &&
                          userIsLoggedIn &&
                          userType == 'retailer'
                        "
                        :content="'Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                        :login="true"
                      >
                      </button-link>
                      <button-link
                        :isInternal="true"
                        v-if="$route.name == 'Contact' && userIsLoggedIn"
                        :content="'Go To Dashboard'"
                        :destination="'/retailer'"
                        :outlined="true"
                        :login="true"
                      >
                      </button-link>

                      <a
                        target="_blank"
                        class="custom-button filled mr-5"
                        href="https://calendly.com/vgang/vgang-for-dropshipers"
                      >
                        Book a demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-on-web">
          <div class="mobile-nav">
            <router-link to="/">
              <img
                src="@/assets/img/logo-header.svg"
                alt="Vgang logo"
                class="logo"
              />
            </router-link>
            <img
              src="@/assets/img/hamburger.png"
              alt="menu-icon"
              class="hamburger"
              @click="handleSideBar"
            />
          </div>
        </div>
        <div ref="sideBar" class="sidebar" :class="menuOpen ? 'open' : ''">
          <div class="header">
            <router-link to="/">
              <img
                src="@/assets/img/logo-header.svg"
                alt="Vgang logo"
                class="logo"
              />
            </router-link>
            <button @click="handleSideBar" class="close-btn"></button>
          </div>

          <div class="menu-container">
            <buttonLink
              :content="'Dropshippers'"
              :destination="'/'"
              :navigation="false"
            >
            </buttonLink>
            <buttonLink :content="'Pricing'" :destination="'/pricing'">
            </buttonLink>
            <buttonLink :content="'Partnership'" :destination="'/partnership'">
            </buttonLink>
            <a target="_blank" class="link" href="https://blog.vgang.io/"
              >Resources</a
            >
            <buttonLink :content="'About Us'" :destination="'/about-us'">
            </buttonLink>
          </div>
          <div class="menu-container menu-footer">
            <a
              target="_blank"
              class="link"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              Shopify app for dropshipers
            </a>
            <a
              target="_blank"
              class="link"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              Shopify app for suppliers
            </a>

            <buttonLink :content="'Contact us'" :destination="'/partnership'">
            </buttonLink>
            <buttonLink :content="'FAQ'" :destination="'/partnership'">
            </buttonLink>
          </div>

          <div class="menu-container menu-footer">
            <buttonLink
              :content="'Terms of Service'"
              :destination="'/partnership'"
            >
            </buttonLink>
            <buttonLink
              :content="'Privacy Policy'"
              :destination="'/partnership'"
            >
            </buttonLink>
          </div>

          <div class="footer custom">
            <div class="buttons-container">
              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'Pricing' &&
                  userIsLoggedIn &&
                  userType == 'supplier'
                "
                :content="' Go To Dashboard'"
                :destination="'/supplier'"
                :outlined="true"
              >
              </button-link>

              <button-link
                :isInternal="true"
                v-if="$route.name == 'AboutUs' && !userIsLoggedIn"
                :content="' Get Started'"
                :destination="'/register/retailer'"
                :filled="true"
                class="mr-3"
              >
              </button-link>
              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'AboutUs' &&
                  userIsLoggedIn &&
                  userType == 'supplier'
                "
                :content="' Go To Dashboard'"
                :destination="'/supplier'"
                :outlined="true"
              >
              </button-link>
              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'AboutUs' &&
                  userIsLoggedIn &&
                  userType == 'retailer'
                "
                :content="' Go To Dashboard'"
                :destination="'/retailer'"
                :outlined="true"
              >
              </button-link>

              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'Home' &&
                  userIsLoggedIn &&
                  userType == 'supplier'
                "
                :content="'Go To Supplier Dashboard'"
                :destination="'/supplier'"
                :outlined="true"
                :login="true"
              >
              </button-link>

              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'Home' &&
                  userIsLoggedIn &&
                  userType == 'retailer'
                "
                :content="' Go To Dashboard'"
                :destination="'/retailer'"
                :outlined="true"
                :login="true"
              >
              </button-link>

              <button-link
                :isInternal="true"
                v-if="
                  $route.name == 'Partnership' &&
                  userIsLoggedIn &&
                  userType == 'retailer'
                "
                :content="'Go To Dashboard'"
                :destination="'/retailer'"
                :outlined="true"
                :login="true"
              >
              </button-link>
            </div>
            <span class="w-100" v-if="userIsLoggedIn">
              <button class="custom-button filled" @click="handleLogout">
                Logout
              </button>
            </span>
            <buttonLink
              v-else
              :isInternal="true"
              :content="'Login for dropshippers'"
              :destination="'/login/retailer'"
              :filled="true"
              :block="true"
              :long="true"
            >
            </buttonLink>
            <button-link
              v-if="!userIsLoggedIn"
              class="join-btn"
              :isInternal="true"
              :content="' Join the movement & get started today'"
              :destination="'/register/retailer'"
              :mobile="true"
            >
            </button-link>
            <buttonLink
              class="schedule-btn"
              :isInternal="true"
              :content="'Schedule a call with us'"
              :destination="'/login/retailer'"
              :block="true"
              :long="true"
            >
            </buttonLink>
          </div>
        </div>
      </header>
      <router-view> </router-view>
      <footer
        class="footer-web"
        v-if="!isShopify && checkRouteForLandingFooter"
      >
        <div class="container">
          <div class="row top-section">
            <div class="col-lg-4 col-md-6">
              <router-link to="/">
                <img
                  src="@/assets/img/v-gang-dark-logo.svg"
                  alt="Vgang logo of footer"
                  class="logo"
              /></router-link>
              <p class="vgang-info">
                VGANG is a AI-powered platform that helps online shops make informed product decisions and drive sales growth. By leveraging cutting-edge algorithms, VGANG predicts product demand based on market trends, shopper behavior, and product attributes. It then matches these in-demand products with the online shops that are most likely to succeed in selling them.
              </p>
              <div class="socials">
                <a
                  href="https://www.facebook.com/vgang.io"
                  class="social"
                  target="_blank"
                >
                  <img
                    src="@/assets/img/facebook1.png"
                    alt="facebook logo"
                    class="logos"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC8s3xKGxNahCvFFiZCLZpUA"
                  class="social"
                  target="_blank"
                >
                  <img
                    src="@/assets/img/youtube.png"
                    alt="youtube logo"
                    class="logos"
                /></a>
                <a
                  href="https://twitter.com/Vgang_io"
                  target="_blank"
                  class="social"
                >
                  <img
                    src="@/assets/img/twitter1.png"
                    alt="twitter logo"
                    class="logos"
                /></a>
                <a
                  href="https://www.linkedin.com/company/vgang-dropshipping/"
                  class="social"
                  target="_blank"
                >
                  <img
                    src="@/assets/img/linkedin1.png"
                    alt="linkedin logo"
                    class="logos"
                /></a>
                <a
                  href="https://www.instagram.com/vgang.io"
                  class="social"
                  target="_blank"
                >
                  <img
                    style="
                      filter: brightness(0) saturate(100%) invert(30%) sepia(6%)
                        saturate(1962%) hue-rotate(177deg) brightness(96%)
                        contrast(84%);
                    "
                    src="@/assets/img/instagram1.png"
                    alt="linkedin logo"
                    class="logos"
                /></a>
              </div>
            </div>

            <div class="applications col-lg-2 col-md-5">
              <p class="app-bold">Company</p>
              <P class="app">
                <router-link to="/partnership"> VGANG for Brands </router-link>
              </P>
              <P class="app">
                <router-link to="/affiliates"> VGANG for Creators </router-link>
              </P>
              <P class="app">
                <a target="_parent" href="https://blog.vgang.io/category/blog/">
                  Blog
                </a>
              </P>
              <P class="app">
                <router-link to="/about-us"> About </router-link>
              </P>
            </div>
            <div class="applications col-lg-2 col-md-6">
              <p class="app-bold">Help Center</p>
              <P class="app">
                <a
                  title="FAQ for dropshipers"
                  target="_blank"
                  href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                >
                  FAQ for dropshippers
                </a>
              </P>
              <P class="app">
                <a
                  title="FAQ for suppliers"
                  target="_blank"
                  href="https://blog.vgang.io/category/vgang-for-suppliers/"
                >
                  FAQ for suppliers
                </a>
              </P>
              <p class="app">
                <router-link to="/contact"> Contact us </router-link>
              </p>
              <P class="app">
                <a
                  target="_blank"
                  href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search#adp-reviews"
                >
                  Reviews
                </a>
              </P>
            </div>
            <div class="applications col-lg-3 col-md-5">
              <p class="app-bold">Apps</p>
              <P class="app">
                <a
                  target="_blank"
                  href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
                >
                  Shopify app for dropshippers
                </a>
              </P>
              <P class="app">
                <a
                  target="_blank"
                  href="https://apps.shopify.com/vgang-dropsipping-suppliers-1?st_source=autocomplete"
                >
                  Shopify app for suppliers
                </a>
              </P>
            </div>
          </div>
        </div>
        <div class="bottom-section">
          <div class="container d-flex justify-space-between">
            <div class="links">
              <buttonLink
                :content="'Terms & conditions'"
                :destination="'/terms'"
                :navigation="true"
              >
              </buttonLink>

              <buttonLink
                :content="'Privacy policy'"
                :destination="'/privacypolicy'"
                :navigation="true"
              >
              </buttonLink>
              <buttonLink
                :content="'Cookie Policy'"
                :destination="'/cookiePolicy'"
                :navigation="true"
              >
              </buttonLink>
              <buttonLink
                :content="'Acceptable Use Policy'"
                :destination="'/acceptableUsePolicy'"
                :navigation="true"
              >
              </buttonLink>
            </div>
            <p class="copyright">
              @2024 Hirawin OÜ. All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  </v-app>
</template>

<script>
// Services

import { mapGetters, mapActions } from "vuex";
import Notification from "@/components/bases/Notification.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";

import CommonData from "@/services/CommonData";
import {
  getVariable,
  setVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
export default {
  components: {
    Notification,
    buttonLink,
  },
  data() {
    return {
      menuOpen: false,
      miniVariant: true,
      userType: "retailer",
      loggedIn: "",
      show: true,
      isOauth: false,
      isShopify: window.self !== window.top ? true : false,
      loading: false,
    };
  },

  created() {
    const allowedToAdd = [
      "/",
      "/contact",
      "/pricing",
      "/affiliates",
      "/partnership",
      "/retailer",
      "/login/retailer",
      "/login/supplier",
      "/retailer/list-of-products/:id",
      "/retailer//supplierProfile/:ID",
      "/retailer/list-of-products",
      "/retailer/my-products",
      "/retailer/import-list",
      "/retailer/checkout",
      "/about-us",
      "/terms",
    ];
  },
  mounted() {
    this.checkRole();
    // SEO Scripts
    if (
      !this.$route.path.includes("/sh-retailer") &&
      !this.$route.path.includes("/supplier")
    ) {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=G-4Z7WQ7SXJS"
      );
      document.head.insertAdjacentHTML(
        "beforeend",
        "<!-- Global site tag (gtag.js) - Google Analytics -->"
      );
      document.head.appendChild(script);
      script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-W8P4MJR');`;
      document.head.insertAdjacentHTML(
        "beforeend",
        "<!-- Google Tag Manager -->"
      );
      document.head.appendChild(script);
      document.head.insertAdjacentHTML(
        "beforeend",
        "<!-- End Google Tag Manager -->"
      );
      script = document.createElement("script");
      script.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-4Z7WQ7SXJS');`;
      document.head.appendChild(script);
      //

      let iframe = document.createElement("iframe");
      iframe.setAttribute(
        "src",
        "https://www.googletagmanager.com/ns.html?id=GTM-W8P4MJR"
      );
      iframe.setAttribute("height", "0");
      iframe.setAttribute("width", "0");
      iframe.setAttribute("style", "display:none;visibility:hidden");
      let noscript = document.createElement("noscript");
      noscript.appendChild(iframe);

      document.body.insertAdjacentHTML(
        "beforeend",
        `<!-- Google Tag Manager (noscript) -->`
      );
      document.body.appendChild(noscript);
      document.body.insertAdjacentHTML(
        "beforeend",
        "<!-- End Google Tag Manager (noscript) -->"
      );
      document.head.insertAdjacentHTML(
        "beforeend",
        "<!-- Google Tag Manager -->"
      );
      script = document.createElement("script");

      if (process.env.VUE_APP_HOTJAR_APP === "vgang.io") {
        document.head.insertAdjacentHTML(
          "beforeend",
          "<!-- Hotjar Tracking Code for https://vgang.io -->"
        );
        script.innerHTML = ` (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3049261,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
        document.head.appendChild(script);
      }

      let lastScrollTop = 0;
      window.addEventListener(
        "scroll",
        () => {
          let st = window.pageYOffset || document.documentElement.scrollTop;
          if (st === 0) {
            this.$refs.header?.classList.remove("sticky");
          }
          // downscroll code
          if (st > lastScrollTop) {
            this.$refs.header?.classList.add("sticky");
            if (this.$refs.header?.classList.contains("sticky")) {
              this.$refs.header.style.top = "-180px";
            }
          }
          // upscroll code
          else {
            if (this.$refs.header?.classList.contains("sticky")) {
              this.$refs.header.style.top = "0";
            }
          }
          lastScrollTop = st <= 0 ? 0 : st; //For Mobile or negative scrolling
        },
        false
      );
    }
  },

  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
    }),
    checkRouteForLandingHeader() {
      if (
        this.$route.name == "Partnership" ||
        this.$route.name == "Home" ||
        this.$route.name == "Contact" ||
        this.$route.name == "Pricing" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "Affiliates" ||
        this.$route.name == "Terms" ||
        this.$route.name == "Privacypolicy" ||
        this.$route.name == "CookiePolicy" ||
        this.$route.name == "AcceptableUsePolicy" ||
        this.$route.name == "Shopify" ||
        this.$route.name == "Single_Product" ||
        this.$route.name == "Blog" ||
        this.$route.name == "wizard" ||
        this.$route.name == "wizard_category" ||
        this.$route.name == "wizard_sub_category" ||
        this.$route.name == "wizard_shipping"
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkRouteForLandingFooter() {
      if (
        this.$route.name == "Partnership" ||
        this.$route.name == "Home" ||
        this.$route.name == "Pricing" ||
        this.$route.name == "Contact" ||
        this.$route.name == "Affiliates" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "Terms" ||
        this.$route.name == "Privacypolicy" ||
        this.$route.name == "CookiePolicy" ||
        this.$route.name == "AcceptableUsePolicy" ||
        this.$route.name == "Shopify" ||
        this.$route.name == "Single_Product" ||
        this.$route.name == "Blog"
      ) {
        return true;
      } else {
        return false;
      }
    },
    userIsLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    onRetailerDashboard() {
      if (this.$route.path.includes("/retailer/")) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      fadeAlert: "alert/fade",
    }),
    async checkRole() {
      if (getVariable("user")) {
        try {
          const response = await CommonData.getCurrentUser();
          this.userType = response.data.role;
          setVariable("role", this.userType);
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
    },
    handleSideBar() {
      this.menuOpen = !this.menuOpen;
    },
    handleLogout() {
      removeVariable("role");
      this.message = "";
      this.submitted = true;
      this.$store.dispatch("auth/logout").then(
        (data) => {
          this.$router.go({ name: "LoginSupplier" });
          this.message = data.message;
          this.successful = true;
          localStorage.removeItem("user");
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
  },

  watch: {
    $route: {
      // this watcher is only for the Window.open() that open the new sign in popup window in the shopify supplier app
      handler: async function (search) {
        if (search.path === "/supplier/oauth") this.isOauth = true;
        if (this.$refs.sideBar?.classList.contains("open")) {
          this.handleSideBar();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
.sticky {
  position: fixed;
  top: -180px;
  right: 0;
  left: 0;
  box-shadow: 0 0 50px 5px rgba(29, 41, 54, 0.1);
  z-index: 333333;
}
.login-btn {
  border: none !important;
}
</style>
