var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notif_wrapper ma-2"},[_c('div',{staticClass:"left_section d-flex align-start justify-center pt-3 px-2",class:_vm.notifType === 'success'
        ? 'success'
        : _vm.notifType === 'warning'
        ? 'warning'
        : 'error'},[(_vm.notifType === 'success')?_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" check_circle ")]):(_vm.notifType === 'warning')?_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" error_outline ")]):(_vm.notifType === 'error')?_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" warning_amber ")]):_vm._e()]),_c('div',{staticClass:"right_section py-3 px-4",class:_vm.notifType === 'success'
        ? 'success_light'
        : _vm.notifType === 'warning'
        ? 'warning_light'
        : 'error_light'},[_c('div',[_c('h4',{staticClass:"notif_title"},[_vm._v(_vm._s(_vm.notifTitle))])]),_c('p',{staticClass:"notif_message mb-0"},[_vm._t("default")],2),(_vm.notifBadges && _vm.notifBadges.length)?_c('div',{staticClass:"notif_badge_container my-2"},_vm._l((_vm.notifBadges),function(badge,index){return _c('button',{key:index,staticClass:"notif_badge"},[_vm._v(" "+_vm._s(badge)+" ")])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }