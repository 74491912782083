import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getPlans() {
    return api.get(
      "retailers/settings/subscriptions/plans",
      {},
      { headers: authHeader() }
    );
  },
  getCards() {
    return api.get("retailers/settings/cards", {}, { headers: authHeader() });
  },
  getAccountInfo() {
    return api.get("retailers/settings/account", {}, { headers: authHeader() });
  },
  updateAccountInfo(data) {
    return api.post("retailers/settings/account", data, {
      headers: authHeader(),
    });
  },
  changePassword(data) {
    return api.post("auth/profile/change-password", data, {
      headers: authHeader(),
    });
  },
  addCard(data) {
    return api.post("retailers/settings/cards", data, {
      headers: authHeader(),
    });
  },
  deleteCard(id) {
    return api.delete(
      "retailers/settings/cards/" + id,
      {},
      { headers: authHeader() }
    );
  },
  setDefault(id) {
    return api.post(
      "retailers/settings/cards/set-default/" + id,
      {},
      { headers: authHeader() }
    );
  },
  getClientSecret(id,coupon = "") {
    return api.post(
      "retailers/settings/subscriptions/purchase/" + id,
      { coupon },
      { headers: authHeader() }
    );
  },
  cancelMembership() {
    return api.post(
      "retailers/settings/subscriptions/cancel",
      {},
      { headers: authHeader() }
    );
  },
};
