<template>
  <div class="notif_wrapper ma-2">
    <div
      class="left_section d-flex align-start justify-center pt-3 px-2"
      :class="
        notifType === 'success'
          ? 'success'
          : notifType === 'warning'
          ? 'warning'
          : 'error'
      "
    >
      <span v-if="notifType === 'success'" class="material-symbols-outlined">
        check_circle
      </span>
      <span
        v-else-if="notifType === 'warning'"
        class="material-symbols-outlined"
      >
        error_outline
      </span>
      <span v-else-if="notifType === 'error'" class="material-symbols-outlined">
        warning_amber
      </span>
    </div>
    <div
      class="right_section py-3 px-4"
      :class="
        notifType === 'success'
          ? 'success_light'
          : notifType === 'warning'
          ? 'warning_light'
          : 'error_light'
      "
    >
      <div>
        <h4 class="notif_title">{{ notifTitle }}</h4>
      </div>
      <p class="notif_message mb-0">
        <slot></slot>
      </p>
      <div
        v-if="notifBadges && notifBadges.length"
        class="notif_badge_container my-2"
      >
        <button
          class="notif_badge"
          v-for="(badge, index) in notifBadges"
          :key="index"
        >
          {{ badge }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    notifType: {
      required: true,
    },
    notifBadges: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {};
  },
  created() {},
  computed: {
    notifTitle() {
      if (this.notifType) {
        return this.notifType.charAt(0).toUpperCase() + this.notifType.slice(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.notif_wrapper {
  display: flex;
  align-items: stretch;
  max-width: 560px;

  .left_section {
    width: 40px;
    background: $primary;
    &.success {
      background: $primary;
    }
    &.warning {
      background: $yellow-500;
    }
    &.error {
      background: $red-500;
    }
  }
  .right_section {
    background: $green-100;
    max-width: calc(734px - 40px);
    height: fit-content;
    &.success_light {
      background: $green-100;
    }
    &.warning_light {
      background: $yellow-100;
    }
    &.error_light {
      background: $red-100;
    }
    .notif_title {
      font-size: $font_size_base + 0.6rem;
      font-weight: bold;
    }
    .notif_message {
      // width: 95%;
      font-size: $font_size_base + 0.4rem;
      font-weight: 600;
      line-height: 1.5;
    }
    .notif_badge_container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .notif_badge {
        background: $grey-700;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05),
          0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 10px;
        padding: 8px 12px;
        font-size: $font_size_base + 0.4rem;
        font-weight: bold;
        line-height: 1.5;
        color: white;
      }
    }
  }
}
</style>