import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";
/**  
@service RetailersImportList Service handle all the requests to the API related to Retailer's Import List  
*/
const RetailerImportListService = {
  /**  
    @description: Get the list of Imported product 
    @param {String} ProductID Id of the product to import
    @returns {Promise} returns a promise that resolves with...
  */
  importProductToList: ProductID => {
    return api.post(`retailers/products/${ProductID}/import`, {
      headers: authHeader(),
    });
  },

  /**  
    @description: Get the list of Imported product 
    @param {Object} pagination contains the offset, count (limit), and  search query
    @returns {Promise} returns a promise that resolves with the list of imported products
  */
  getImportedList: pagination => {
    return api.get(
      "retailers/importlist",
      { params: pagination },
      { headers: authHeader() }
    );
  },

  /**  
    @description: Update the inported product on 
    @param {String} product the product to update
    @returns {Promise} returns a promise that resolves with the updated imported product
  */
  updateImportedList: product => {
    return api.put(`retailers/importlist/${product.id}`, product, {
      headers: authHeader(),
    });
  },

  /**  
    @description: Push the product to the store 
    @param {String} importedProductID Id of the product to submit
    @returns {Promise} returns a promise that resolves with ....
  */
  submitImportedList: importedProductID => {
    return api.post(`retailers/importlist/${importedProductID}/submit`, {
      headers: authHeader(),
    });
  },

  /**  
    @description: remove the product from the imported list
    @param {String} importedProductID Id of the product to remove
    @returns {Promise} returns a promise that resolves with ....
  */
  removeImportedList: importedProductID => {
    return api.delete(`retailers/importlist/${importedProductID}`, {
      headers: authHeader(),
    });
  },
};
export default RetailerImportListService;
