import RetailerImportListService from "@/services/RetailerImportList";
import RetailerSettingService from "@/services/SettingService";
import CommonData from "@/services/CommonData";
const clearAlert = (dispatch) => {
  setTimeout(() => {
    dispatch(
      "alert/clear",
      {},
      {
        root: true,
      }
    );
  }, 3000);
};
export const retailer = {
  namespaced: true,
  state: {
    // User
    currentUserInfo: {},
    currentUserAccountInfo: {},

    // Import Product List
    importedProductList: [],
    importedProductListTotalCount: 0,
    listOfProductsPagination: {},
    listOfSellers: [],

    // my Products
    myProductsPagination: {},

    // Setting
    plansSubscription: {},

    // Banners Status
    showMainBanner: false,

    // Breadcrumbs
    Breadcrumbs: [],

    // products Count
    ProductsCount: undefined,
  },
  getters: {
    // User
    currentUserInformation: (state) => state.currentUserInfo,
    currentUserAccountInformation: (state) => state.currentUserAccountInfo,

    // Import Product List
    importedProductList: (state) => state.importedProductList,
    importedProductListTotalCount: (state) =>
      state.importedProductListTotalCount,
    listOfProductsPagination: (state) => state.listOfProductsPagination,
    listOfSellers: (state) => state.listOfSellers,

    // My Products
    myProductsPagination: (state) => state.myProductsPagination,

    // Setting
    listOfPlansSubscription: (state) => state.plansSubscription,
    currentPlanSubscription: (state) =>
      (state.plansSubscription.length &&
        state.plansSubscription.find((plan) => plan.userSub)) ||
      null,

    // Banners Status
    showMainBanner: (state) => state.showMainBanner,

    // Banners Status
    Breadcrumbs: (state) => state.Breadcrumbs,

    // Get products Count
    ProductsCount: (state) => state.ProductsCount,
  },
  actions: {
    // User
    async getCurrentUserInformation({ commit }) {
      try {
        const response = await CommonData.getCurrentUser();
        commit("SET_CURRENT_USER_INFORMATION", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrentUserAccountInformation({ commit, state }, forced = false) {
      try {
        if (Object.keys(state.currentUserAccountInfo).length === 0 || forced) {
          const response = await RetailerSettingService.getAccountInfo();
          commit("SET_CURRENT_USER_ACCOUNT_INFORMATION", response.data);
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // Import Product List
    async importProductToList({ dispatch, commit }, productID) {
      try {
        const response = await RetailerImportListService.importProductToList(
          productID
        );
        if (response.status === 200) {
          commit("IMPORT_PRODUCT_TO_LIST", response);
          dispatch(
            "alert/newAlert",
            { type: "success", message: response.data.message },
            { root: true }
          );
          clearAlert(dispatch);
          if (response.data.importListID >= 0)
            return response.data.importListID;
          else throw response.data.message;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "error", message: response.data.message },
            {
              root: true,
            }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "error", message: error?.message || error?.data.message },
          {
            root: true,
          }
        );
        clearAlert(dispatch);
      }
    },

    async getImportedList({ dispatch, commit }, pagination) {
      try {
        const response = await RetailerImportListService.getImportedList(
          pagination
        );

        if (response.status === 200) {
          commit("SET_IMPORTED_PRODUCT_LIST", response.data);
          commit("SET_LIST_OF_SELLERS", response.data.sellers);
          return true;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "error", message: response.data.message },
            {
              root: true,
            }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "error", message: error.data.message },
          {
            root: true,
          }
        );
        clearAlert(dispatch);
      }
    },

    async updateImportedList({ dispatch, commit }, product) {
      try {
        const response = await RetailerImportListService.updateImportedList(
          product
        );

        if (response.status === 200) {
          commit("UPDATE_IMPORTED_PRODUCT", product);
          dispatch(
            "alert/newAlert",
            { type: "success", message: response.data.message },
            { root: true }
          );
          clearAlert(dispatch);
          return true;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "error", message: response.data.message },
            { root: true }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "error", message: error?.message || error?.data.message },
          { root: true }
        );
        clearAlert(dispatch);
      }
    },

    async submitImportedList({ dispatch, commit }, importedProductID) {
      try {
        const response = await RetailerImportListService.submitImportedList(
          importedProductID
        );
        if (response.status === 200) {
          dispatch(
            "alert/newAlert",
            { type: "success", message: response.data.message },
            { root: true }
          );
          clearAlert(dispatch);
          commit("DELETE_IMPORTED_PRODUCT", importedProductID);

          return true;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "error", message: response.data.message },
            {
              root: true,
            }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "error", message: error?.message || error?.data.message },
          {
            root: true,
          }
        );
        clearAlert(dispatch);
      }
    },

    async removeImportedList({ dispatch, commit }, importedProductID) {
      try {
        const response = await RetailerImportListService.removeImportedList(
          importedProductID
        );

        if (response.status === 200) {
          dispatch(
            "alert/newAlert",
            { type: "success", message: response.data.message },
            { root: true }
          );
          clearAlert(dispatch);
          commit("DELETE_IMPORTED_PRODUCT", importedProductID);
          return true;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "error", message: response.data.message },
            {
              root: true,
            }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "error", message: error?.message || error?.data.message },
          {
            root: true,
          }
        );
        clearAlert(dispatch);
      }
    },

    async setListOfProductsPagination({ commit }, pag) {
      commit("SET_LIST_PROD_PAGINATION", pag);
      return;
    },

    // Banners Status
    updateShowMainBanner({ commit }, status) {
      commit("UpdateShowMainBanner", status);
      return true;
    },

    // Breadcrumbs
    updateBreadcrumbs({ commit }, status) {
      commit("SetBreadcrumbs", status);
      return true;
    },

    updateProductsCount({ commit }, count) {
      commit("SetProductsCount", count);
      return true;
    },

    // async getListOfSellers({ commit }) {
    //   try {
    //     const response = await CommonData.getTags();
    //     commit("SET_LIST_OF_SELLERS", response.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    // My Products
    async setMyProductsPagination({ commit }, pag) {
      commit("SET_MY_PROD_PAGINATION", pag);
      return;
    },

    // Setting
    async getPlansSubscription({ dispatch, commit }) {
      try {
        const response = await RetailerSettingService.getPlans();

        if (response.status === 200) {
          commit("SET_PLANS_SUBSCRIPTION", response.data);
          return true;
        } else {
          dispatch(
            "alert/newAlert",
            { type: "", message: response.data.message },
            {
              root: true,
            }
          );
          clearAlert(dispatch);
          return false;
        }
      } catch (error) {
        dispatch(
          "alert/newAlert",
          { type: "", message: error?.message || error?.data.message },
          {
            root: true,
          }
        );
        clearAlert(dispatch);
      }
    },
  },
  mutations: {
    // User
    SET_CURRENT_USER_INFORMATION(state, userInfo) {
      state.currentUserInfo = userInfo;
    },
    SET_CURRENT_USER_ACCOUNT_INFORMATION(state, userInfo) {
      state.currentUserAccountInfo = userInfo;
      // if (userInfo.shopUrl && userInfo.shopInstalled) {
      //   state.currentUserAccountInfo.shopInstalled = true;
      // } else {
      //   state.currentUserAccountInfo.shopInstalled = false;
      // }
    },

    // List of Products
    IMPORT_PRODUCT_TO_LIST(state, importedProduct) {
      state.importedProductList.push(importedProduct);
    },

    SET_IMPORTED_PRODUCT_LIST(state, importedProductList) {
      state.importedProductList = importedProductList.importList;
      state.importedProductListTotalCount = importedProductList.totalCount;
    },

    UPDATE_IMPORTED_PRODUCT(state, updatedProduct) {
      const index = state.importedProductList.findIndex(
        (importedProduct) => importedProduct.id === updatedProduct.id
      );
      state.importedProductList[index] = updatedProduct;
    },

    DELETE_IMPORTED_PRODUCT(state, deletedProductID) {
      const index = state.importedProductList.findIndex(
        (importedProduct) => importedProduct.id === deletedProductID
      );
      state.importedProductList.splice(index, 1);
      state.importedProductListTotalCount--;
    },

    SET_LIST_PROD_PAGINATION(state, pag) {
      state.listOfProductsPagination = pag;
    },

    SET_LIST_OF_SELLERS(state, sellers) {
      state.listOfSellers = sellers;
    },

    // My Products
    SET_MY_PROD_PAGINATION(state, pag) {
      state.myProductsPagination = pag;
    },

    // Settings
    SET_PLANS_SUBSCRIPTION(state, plans) {
      state.plansSubscription = plans;
    },

    // Banners Status
    UpdateShowMainBanner(state, status) {
      state.showMainBanner = status;
    },

    // Banners Status
    SetBreadcrumbs(state, newPaths) {
      state.Breadcrumbs = newPaths;
    },

    SetProductsCount(state, newCount) {
      state.ProductsCount = newCount;
    },
  },
};
