import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getCountries() {
    return api.get("common/countries", {}, { headers: authHeader() });
  },
  getProvinces(countryID) {
    return api.get(
      "common/countries/" + countryID + "/provinces",
      {},
      { headers: authHeader() }
    );
  },
  getCitiesByCountry(countryID) {
    return api.get(
      "common/countries/" + countryID + "/cities",
      {},
      { headers: authHeader() }
    );
  },
  getCities(provinceID) {
    return api.get(
      "common/provinces/" + provinceID + "/cities",
      {},
      { headers: authHeader() }
    );
  },
  getCategories() {
    return api.get("common/categories", {}, { headers: authHeader() });
  },
  getSubCategories() {
    return api.get("common/categories", {}, { headers: authHeader() });
  },
  getTags() {
    return api.get("common/seller-tags", {}, { headers: authHeader() });
  },
  getStripeLink() {
    return api.get("retailers/portal/link", {}, { headers: authHeader() });
  },
  getCurrentUser() {
    return api.get("auth/profile", {}, { headers: authHeader() });
  },
};
