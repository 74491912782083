import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/assets/scss/main.scss";
import setupInterceptors from "./services/setupInterceptors";
import UUID from "vue-uuid";
import CKEditor from "ckeditor4-vue";
import VueMeta from 'vue-meta';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "material-icons/iconfont/material-icons.css";

import "bootstrap/dist/css/bootstrap.css";

import axios from "axios";
import VueAxios from "vue-axios";
import VueClipboard from "vue-clipboard2";

import PolarisVue from "@hulkapps/polaris-vue";

Vue.use(VueClipboard);

Vue.use(VueAxios, axios);

Vue.use(UUID);
 Vue.use(CKEditor);
Vue.use(VueMeta);

// Make BootstrapVue available throughout your project

Vue.use(PolarisVue);
// Optionally install the BootstrapVue icon components plugin

Vue.config.productionTip = false;
setupInterceptors(store);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
