import axios from "axios";
import { getSessionToken ,isShopifyEmbedded} from '@shopify/app-bridge/utilities';
import createApp from "@shopify/app-bridge";
import { getVariable, setVariable } from "@/utils/conditionalStoreVariables";

const testBaseUrl = getVariable("testBaseUrl");
const secretAccess = getVariable("secretAccess");
const url_string = window.location.href;
const url = new URL(url_string);

const baseURL =
  secretAccess &&
  process.env.VUE_APP_SECRET_ACCESS &&
  secretAccess === process.env.VUE_APP_SECRET_ACCESS
    ? testBaseUrl
    : process.env.VUE_APP_BASEURL;

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
if (
  process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
  isShopifyEmbedded()
) {
  instance.interceptors.request.use(async (config) => {
    let apiKey = "";
    let appSource = "";
    let appSourceName = "";

    if (url.pathname.includes("/sh-retailer")) {
      apiKey = process.env.VUE_APP_SHOPIFY_RETAILER_API_KEY;
      appSource = process.env.VUE_APP_SOURCE_RETAILER;
      appSourceName = process.env.VUE_APP_SOURCE_NAME_RETAILER;
    } else {
      apiKey = process.env.VUE_APP_SHOPIFY_API_KEY;
      appSource = process.env.VUE_APP_SOURCE;
      appSourceName = process.env.VUE_APP_SOURCE_NAME;
    }

    const host = getVariable("host");
    const user = getVariable("user");

    const app = createApp({
      apiKey: apiKey,
      host: host,
    });
    return getSessionToken(app) // requires an App Bridge instance
      .then((token) => {
        setVariable("shopifyToken", token);
        // Append your request headers with an authenticated token

        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Source"] = appSource;
        config.headers["Source-Name"] = appSourceName;
        return config;
      });
  });
}

export default instance;
