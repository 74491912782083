import { getVariable } from "@/utils/conditionalStoreVariables";
export default function authHeader() {
  let user = getVariable("user");

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
}
