import Vue from "vue";
import VueRouter from "vue-router";

import RetailerHomeRoutes from "@/views/retailer/Home/retailerhome.routes.js";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },

  {
    path: "/partnership",
    name: "Partnership",
    component: () => import("@/views/Partnership.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/views/Pricing.vue"),
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    component: () => import("@/views/Affiliates.vue"),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("@/views/AboutUs.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/Terms.vue"),
  },
  {
    path: "/privacypolicy",
    name: "Privacypolicy",
    component: () => import("@/views/Privacypolicy.vue"),
  },
  {
    path: "/cookiePolicy",
    name: "CookiePolicy",
    component: () => import("@/views/CookiePolicy.vue"),
  },
  {
    path: "/acceptableUsePolicy",
    name: "AcceptableUsePolicy",
    component: () => import("@/views//AcceptableUsePolicy.vue"),
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: () => import("@/views/Supplier.vue"),
    children: [
      {
        path: "shipping",
        name: "SupplierShipping",
        component: () => import("@/views/supplier/Shipping.vue"),
      },
      {
        path: "setting",
        name: "SupplierSetting",
        component: () => import("@/views/supplier/Setting.vue"),
      },

      {
        path: "assign-categoryV2",
        name: "My_ProductsV2",
        component: () => import("@/views/supplier/AssignCategoryV2.vue"),
      },
      {
        path: "order-list",
        name: "Order_List",
        component: () => import("@/views/supplier/OrderList.vue"),
      },
      {
        path: "login",
        name: "SupplierLogin",
        component: () => import("@/views/supplier/Login.vue"),
      },

      {
        path: "order-detail/:id",
        name: "Order_Detail",
        component: () => import("@/views/supplier/OrderDetail.vue"),
      },
      {
        path: "onboarding",
        name: "SupplierOnboarding",
        component: () =>
          import("@/views/supplier/form-wizard/SupplierFormWizard.vue"),
      },
    ],
  },

  {
    path: "/supplier/oauth",
    name: "SupplierOauth",
    component: () => import("@/views/supplier/Oauth.vue"),
  },

  {
    path: "/sh-retailer-oath/oauth",
    name: "ShopifyRetailerOauth",
    component: () =>
      import("@/views/shopify-retailer/authentication/Oauth.vue"),
  },
  {
    path: "/sh-retailer",
    name: "ShopifyRetailer",
    component: () => import("@/views/shopify-retailer/sh-retailer.vue"),
    children: [
      {
        path: "onboarding",
        name: "ShopifyRetailerOnboarding",
        component: () =>
          import("@/views/shopify-retailer/onboarding/OnboardingRetailer.vue"),
      },

       {
         path: "home",
         name: "Product list home",
         component: () => import("@/views/retailer/Home/RetailerHome.vue"),
       },

      {
        path: "list-of-products/:id",
        name: "ShopifyRetailerProductDetail",
        component: () => import("@/views/shopify-retailer/Detail.vue"),
      },
      {
        path: "supplierProfile/:id",
        name: "ShopifyRetailerSupplierProfile",
        component: () => import("@/views/retailer/supplierProfile.vue"),
      },

      {
        path: "welcome-page",
        name: "ShopifyRetailerWelcomePage",
        component: () => import("@/views/shopify-retailer/WelcomePage.vue"),
      },

      {
        path: "list-of-products",
        name: "ShopifyRetailerFindProducts",
        component: () => import("@/views/shopify-retailer/FindProducts.vue"),
      },
      {
        path: "products/categories",
        name: "ShopifyRetailerProductsCategories",
        component: () => import("@/views/shopify-retailer/Categories.vue"),
      },
      {
        path: "edit-product/:id",
        name: "ShopifyRetailerEditProduct",
        component: () => import("@/views/shopify-retailer/editProduct.vue"),
      },

      {
        path: "import-list",
        name: "ShopifyRetailerImportList",
        component: () => import("@/views/shopify-retailer/ImportList.vue"),
      },

      {
        path: "checkout",
        name: "ShopifyRetailerCheckout",
        component: () => import("@/views/shopify-retailer/Orders.vue"),
      },
      {
        path: "my-products",
        name: "ShopifyRetailerMyProducts",
        component: () => import("@/views/shopify-retailer/MyProducts.vue"),
      },
      {
        path: "setting",
        name: "ShopifyRetailerSetting",
        component: () => import("@/views/shopify-retailer/Setting.vue"),
      },
    ],
  },

  {
    path: "/retailer",
    name: "Retailer",
    component: () => import("@/views/Retailer.vue"),
  },
  {
    path: "/retailer/setting",
    name: "SettingRetailer",
    component: () => import("@/views/retailer/Setting.vue"),
  },

  {
    path: "/register/retailer",
    name: "RegisterRetailer",
    component: () => import("@/views/authentication/register-retailer.vue"),
  },
  {
    path: "/connectShop/retailer",
    name: "connectShop",
    component: () => import("@/views/authentication/connectShop-retailer.vue"),
  },
  {
    path: "/login/retailer",
    name: "LoginRetailer",
    component: () => import("@/views/authentication/login-retailer.vue"),
  },
  {
    path: "/forgot-pwd/retailer",
    name: "ForgotPasswordRetailer",
    component: () => import("@/views/authentication/forgot-pwd-retailer.vue"),
  },
  {
    path: "/reset-pwd/retailer",
    name: "ResetPasswordRetailer",
    component: () => import("@/views/authentication/reset-pwd-retailer.vue"),
  },
  {
    path: "/retailer/list-of-products/:id",
    name: "Product detail",
    component: () => import("@/views/retailer/list-of-products/detail.vue"),
  },

  {
    path: "/retailer/home",
    name: "Product list home",
    component: () => import("@/views/retailer/Home/Home.vue"),
    children: RetailerHomeRoutes,
  },
  {
    path: "/retailer/my-products",
    name: "My Product list",
    component: () => import("@/views/retailer/MyProducts.vue"),
  },
  {
    path: "/retailer/internal-list",
    name: "InternalList",
    component: () => import("@/views/retailer/InternalList.vue"),
  },
  {
    path: "/retailer/import-list",
    name: "ImportList",
    component: () => import("@/views/retailer/ImportListV2.vue"),
  },
  {
    path: "/retailer/edit-product/:id",
    name: "editProduct",
    component: () => import("@/views/shopify-retailer/editProduct.vue"),
  },
  {
    path: "/retailer/checkout",
    name: "Checkout",
    component: () => import("@/views/retailer/Checkout.vue"),
  },
  {
    path: "/retailer/supplierProfile/:id",
    name: "supplierProfile",
    component: () => import("@/views/retailer/supplierProfile"),
  },
  // {
  //   path: "/retailer/letsStart/setup",
  //   name: "first",
  //   component: () => import("@/components/retailer/letsStart/setup.vue"),
  // },
  // {
  //   path: "/retailer/wizard/start",
  //   name: "wizard",
  //   component: () => import("@/views/wizard/landing.vue"),
  // },
  // {
  //   path: "/retailer/wizard/category",
  //   name: "wizard_category",
  //   component: () => import("@/views/wizard/category.vue"),
  // },
  // {
  //   path: "/retailer/wizard/sub_category",
  //   name: "wizard_sub_category",
  //   component: () => import("@/views/wizard/subCategory.vue"),
  // },
  // {
  //   path: "/retailer/wizard/shipping",
  //   name: "wizard_shipping",
  //   component: () => import("@/views/wizard/shipTo.vue"),
  // },
  // {
  //   path: "/retailer/letsStart/firstStep",
  //   name: "first",
  //   component: () => import("@/components/retailer/letsStart/firstStep.vue"),
  // },
  // {
  //   path: "/retailer/letsStart/secondStep",
  //   name: "second",
  //   component: () => import("@/components/retailer/letsStart/secondStep.vue"),
  // },
  // {
  //   path: "/retailer/letsStart/thirdStep",
  //   name: "third",
  //   component: () => import("@/components/retailer/letsStart/thirdStep.vue"),
  // },
  // {
  //   path: "/retailer/letsStart/fourthStep",
  //   name: "fourth",
  //   component: () => import("@/components/retailer/letsStart/fourthStep.vue"),
  // },

  {
    path: "/register/supplier",
    name: "RegisterSupplier",
    component: () => import("@/views/authentication/register-supplier.vue"),
  },
  {
    path: "/login/supplier",
    name: "LoginSupplier",
    component: () => import("@/views/authentication/login-supplier.vue"),
  },

  {
    path: "/single-product/:id",
    name: "Single_Product",
    component: () => import("@/views/SingleProduct.vue"),
  },

  {
    path: "*",
    name: "NotFound",
    component: () => import("@/components/errors/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/sh-retailer",
    "/sh-retailer/onboarding",
    "/sh-retailer/welcome-page",
    "/sh-retailer/login",
    "/sh-retailer/register",
    "/sh-retailer/list-of-products",
    "/sh-retailer/my-products",
    "/sh-retailer/checkout",
    "/sh-retailer/setting",
    "/sh-retailer-oath/oauth",
    "/sh-retailer/list-of-products/:id",
    "/sh-retailer/edit-product/:id",
    "/login/supplier",
    "/login/retailer",
    "/forgot-pwd/retailer",
    "/reset-pwd/retailer",
    "/register/supplier",
    "/register/retailer",
    "/connectShop/retailer",
    "/retailer/supplierProfile",
    "/",
    "/partnership",
    "/pricing",
    "/affiliates",
    "/about-us",
    "/terms",
    "/contact",
    "/privacypolicy",
    "/cookiePolicy",
    "/acceptableUsePolicy",
    "/single-product/" + to.params.id,
    "/supplier",
    "/supplier/oauth",
    "/supplier/login",
    "/supplier/assign-category",
    "/supplier/order-list",
    "/supplier/order-detail/" + to.params.id,
    "/blog",
    "/retailer/wizard/start",
    "/retailer/wizard/category",
    "/retailer/wizard/sub_category",
    "/retailer/wizard/shipping",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = getVariable("user");

  // trying to access a restricted page + not logged in
  if (authRequired && !loggedIn && to.path == "/supplier") {
    // const currency_iso = this.$route.query?.currency_iso;
    // const shop_id = this.$route.query?.shop_id;
    // const platform_name = this.$route.query?.platform_name;
    // const timestamp = this.$route.query?.timestamp;
    // const state = this.$route.query?.state;
    // const hmac = this.$route.query?.hmac;

    if (to.query.platform_name && to.query.shop_id) {
      setVariable(
        "currency_iso",
        to.query.currency_iso ? to.query.currency_iso : ""
      );
      setVariable("shop_id", to.query.shop_id ? to.query.shop_id : "");
      setVariable(
        "platform_name",
        to.query.platform_name ? to.query.platform_name : ""
      );
      setVariable("timestamp", to.query.timestamp ? to.query.timestamp : "");
      setVariable("state", to.query.state ? to.query.state : "");
      setVariable("hmac", to.query.hmac ? to.query.hmac : "");
    }

    window.location.replace("/partnership");
  } else if (authRequired && !loggedIn && to.path == "/retailer") {
    // const currency_iso = this.$route.query?.currency_iso;
    // const shop_id = this.$route.query?.shop_id;
    // const platform_name = this.$route.query?.platform_name;
    // const timestamp = this.$route.query?.timestamp;
    // const state = this.$route.query?.state;
    // const hmac = this.$route.query?.hmac;
    if (to.query.platform_name && to.query.shop_id) {
      setVariable(
        "currency_iso",
        to.query.currency_iso ? to.query.currency_iso : ""
      );
      setVariable("shop_id", to.query.shop_id ? to.query.shop_id : "");
      setVariable(
        "platform_name",
        to.query.platform_name ? to.query.platform_name : ""
      );
      setVariable("timestamp", to.query.timestamp ? to.query.timestamp : "");
      setVariable("state", to.query.state ? to.query.state : "");
      setVariable("hmac", to.query.hmac ? to.query.hmac : "");
    }

    window.location.replace("/");
  } else if (authRequired && !loggedIn) {
    window.location.replace("/");
  } else {
    // if (to.path == "/retailer") {
    //   window.location.replace("/retailer/list-of-products");
    // }
    next();
  }
});

export default router;
