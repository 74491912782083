var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isInternal)?_c('router-link',{attrs:{"to":_vm.destination}},[_c('button',{staticClass:"custom-button",class:_vm.setclass(
        _vm.filled,
        _vm.block,
        _vm.long,
        _vm.outlined,
        _vm.deactive,
        _vm.back,
        _vm.reversed,
        _vm.login,
        _vm.heroButton,
        _vm.columnButton,
        _vm.columnWhiteButton,
        _vm.cardButton,
        _vm.sectionButton,
        _vm.mobile,
        _vm.longButton,
        _vm.border
      )},[_vm._v(" "+_vm._s(_vm.src)+" "+_vm._s(_vm.content)+" ")])]):(_vm.navigation)?_c('router-link',{staticClass:"lin",attrs:{"to":_vm.destination}},[_vm._v(" "+_vm._s(_vm.content)+" ")]):(_vm.vegangLogo)?_c('router-link',{attrs:{"to":_vm.destination}},[_c('img',{staticClass:"auth-header-logo",attrs:{"src":require("@/assets/img/logo-header.svg"),"alt":"Vgang logo"}})]):(_vm.greenText)?_c('router-link',{attrs:{"to":_vm.destination}},[_c('span',{staticClass:"green-text"},[_vm._v(" "+_vm._s(_vm.content))])]):_c('router-link',{staticClass:"link",attrs:{"to":_vm.destination}},[_vm._v(" "+_vm._s(_vm.content)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }