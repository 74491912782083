import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
class TokenService {
  getLocalRefreshToken() {
    const user = getVariable("user");
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = getVariable("user");
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = getVariable("user");
    user.accessToken = token;
    setVariable("user", user);
  }

  getUser() {
    const user = getVariable("user");
    return user;
  }

  setUser(user) {
    setVariable("user", user);
  }

  removeUser() {
    removeVariable("user");
  }
}

export default new TokenService();
