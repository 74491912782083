<template>
  <router-link v-if="isInternal" :to="destination">
    <button
      class="custom-button"
      :class="
        setclass(
          filled,
          block,
          long,
          outlined,
          deactive,
          back,
          reversed,
          login,
          heroButton,
          columnButton,
          columnWhiteButton,
          cardButton,
          sectionButton,
          mobile,
          longButton,
          border
        )
      "
    >
      {{ src }} {{ content }}
    </button>
  </router-link>
  <router-link v-else-if="navigation" :to="destination" class="lin">
    {{ content }}
  </router-link>
  <router-link v-else-if="vegangLogo" :to="destination">
    <img
      src="@/assets/img/logo-header.svg"
      alt="Vgang logo"
      class="auth-header-logo"
    />
  </router-link>
  <router-link v-else-if="greenText" :to="destination">
    <span class="green-text"> {{ content }}</span>
  </router-link>
  <router-link v-else :to="destination" class="link">
    {{ content }}
  </router-link>
</template>

<script>
export default {
  props: {
    prefix: { type: String, default: "", required: false },
    suffix: { type: String, default: "", required: false },
    content: { type: String },
    src: { type: String, default: "" },
    isInternal: { type: Boolean, default: false, required: false },
    navigation: { type: Boolean, default: false, required: false },
    vegangLogo: { type: Boolean, default: false, required: false },
    greenText: { type: Boolean, default: false, required: false },
    destination: { type: String },
    isActive: { type: Boolean, default: true, required: false },
    filled: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    back: { type: Boolean, default: false },
    long: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    deactive: { type: Boolean, default: false },
    reversed: { type: Boolean, default: false },
    login: { type: Boolean, default: false },
    heroButton: { type: Boolean, default: false },
    columnButton: { type: Boolean, default: false },
    columnWhiteButton: { type: Boolean, default: false },
    cardButton: { type: Boolean, default: false },
    sectionButton: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    longButton: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
  },
  methods: {
    setclass(
      filled,
      block,
      long,
      outlined,
      deactive,
      back,
      reversed,
      login,
      heroButton,
      columnButton,
      columnWhiteButton,
      cardButton,
      sectionButton,
      mobile,
      longButton,
      border
    ) {
      return {
        filled: filled,
        block: block,
        long: long,
        outlined: outlined,
        reversed: reversed,
        login: login,
        heroButton: heroButton,
        columnButton: columnButton,
        columnWhiteButton: columnWhiteButton,
        cardButton: cardButton,
        sectionButton: sectionButton,
        mobile: mobile,
        longButton: longButton,
        deactive: deactive,
        back: back,

        "border-unset": border,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.link-content {
  font-size: 12px;
  margin-bottom: 12px;
  color: $grey-500;
}

.link {
  color: $primary;
}
.border-unset {
  border: unset !important;
}
</style>
