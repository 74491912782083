import axiosInstance from "./Api";
import TokenService from "./TokenService";

const setup = (store) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        // config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const error = await err.response;
      const originalConfig = err.config;
      console.log("originalConfig err", err);

      if (originalConfig?.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/auth/relogin", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const { accessToken } = rs.data;
            store.dispatch("auth/refreshToken", accessToken);
            TokenService.updateLocalAccessToken(accessToken);
            return axiosInstance(originalConfig);
          } catch (_error) {
            store.dispatch("auth/logout");
            const error = await _error.response;

            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default setup;
