import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getSupplierDetail() {
    return api.get("sellers/settings", { headers: authHeader() });
  },
  updategetSupplierDetail(data) {
    return api.post("sellers/settings", data, { headers: authHeader() });
  },
};
