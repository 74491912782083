import store from "@/store";

export const setVariable = (variableName, variableValue) => {
  if (
    process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
    window.self !== window.top
  ) {
    // Use the store global state to set the variable
    const SetGlobalVariable = {
      global_host: (variableValue) =>
        store.dispatch("global/setGlobalHost", variableValue),
      global_user: (variableValue) =>
        store.dispatch("global/setGlobalUser", variableValue),
      global_email: (variableValue) =>
        store.dispatch("global/setGlobalEmail", variableValue),
      global_currency_iso: (variableValue) =>
        store.dispatch("global/setGlobalCurrencyISO", variableValue),
      global_shop_id: (variableValue) =>
        store.dispatch("global/setGlobalShopId", variableValue),
      global_platform_name: (variableValue) =>
        store.dispatch("global/setGlobalPlatformName", variableValue),
      global_timestamp: (variableValue) =>
        store.dispatch("global/setGlobalTimestamp", variableValue),
      global_state: (variableValue) =>
        store.dispatch("global/setGlobalState", variableValue),
      global_hmac: (variableValue) =>
        store.dispatch("global/setGlobalHmac", variableValue),
      global_role: (variableValue) =>
        store.dispatch("global/setGlobalRole", variableValue),
      global_shopifyToken: (variableValue) =>
        store.dispatch("global/setGlobalShopifyToken", variableValue),
      global_host_test: (variableValue) =>
        store.dispatch("global/setGlobalHostTest", variableValue),
      global_iframe_host: (variableValue) =>
        store.dispatch("global/setGlobalIframeHost", variableValue),
    };
    const newName = `global_${variableName}`;
    if (typeof SetGlobalVariable[newName] == "function") {
      SetGlobalVariable[newName](variableValue);
      return;
    }
  } else {
    // Use the LocalStorage state to set the variable
    if (variableName == "user") {
      localStorage.setItem("user", JSON.stringify(variableValue));
      return;
    } else if (variableName == "iFrameHost") {
      localStorage.setItem("iFrameHost", JSON.stringify(variableValue));
      return;
    } else {
      localStorage.setItem(variableName, variableValue);
      return;
    }
  }
};

export const getVariable = (variableName) => {
  if (
    process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
    window.self !== window.top
  ) {
    // Use the store global state to get the variable
    const GetGlobalVariable = {
      global_host: () => store.getters["global/getGlobalHost"],
      global_user: () => store.getters["global/getGlobalUser"],
      global_email: () => store.getters["global/getGlobalEmail"],
      global_currency_iso: () => store.getters["global/getGlobalCurrencyISO"],
      global_shop_id: () => store.getters["global/getGlobalShopId"],
      global_platform_name: () => store.getters["global/getGlobalPlatformName"],
      global_timestamp: () => store.getters["global/getGlobalTimestamp"],
      global_state: () => store.getters["global/getGlobalState"],
      global_hmac: () => store.getters["global/getGlobalHmac"],
      global_role: () => store.getters["global/getGlobalRole"],
      global_shopifyToken: () => store.getters["global/getGlobalShopifyToken"],
      global_host_test: () => store.getters["global/getGlobalHostTest"],
      global_iframe_host: () => store.getters["global/getGlobalIframeHost"],
    };
    const newName = `global_${variableName}`;

    if (typeof GetGlobalVariable[newName] == "function") {
      return GetGlobalVariable[newName]() || null;
    }
  } else {
    // Use the LocalStorage state to get the variable

    if (variableName == "user") return JSON.parse(localStorage.getItem("user"));
    if (variableName == "iFrameHost")
      return JSON.parse(localStorage.getItem("iFrameHost"));
    else return localStorage.getItem(variableName) || null;
  }
};

export const removeVariable = (variableName) => {
  if (
    process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
    window.self !== window.top
  ) {
    // Use the store global state to set the variable
    const removeGlobalVariable = {
      global_host: () => store.dispatch("global/removeGlobalHost", null),
      global_user: () => store.dispatch("global/removeGlobalUser", null),
      global_email: () => store.dispatch("global/removeGlobalEmail", null),
      global_currency_iso: () =>
        store.dispatch("global/removeGlobalCurrencyISO", null),
      global_shop_id: () => store.dispatch("global/removeGlobalShopId", null),
      global_platform_name: () =>
        store.dispatch("global/removeGlobalPlatformName", null),
      global_timestamp: () =>
        store.dispatch("global/removeGlobalTimestamp", null),
      global_state: () => store.dispatch("global/removeGlobalState", null),
      global_hmac: () => store.dispatch("global/removeGlobalHmac", null),
      global_role: () => store.dispatch("global/removeGlobalRole", null),
      global_shopifyToken: () =>
        store.dispatch("global/removeGlobalShopifyToken", null),
      global_host_test: () =>
        store.dispatch("global/removeGlobalHostTest", null),
      global_iframe_host: () =>
        store.dispatch("global/removeGlobalIframeHost", null),
    };
    const newName = `global_${variableName}`;
    if (typeof removeGlobalVariable[newName] == "function") {
      removeGlobalVariable[newName](null);
      return;
    }
  } else {
    // Use the LocalStorage state to set the variable
    localStorage.removeItem(variableName);
    return;
  }
};
