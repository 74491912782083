import AuthService from "@/services/AuthenticationService";
import SupplierSettingService from "@/services/SupplierSetting";
export const supplier = {
  namespaced: true,
  state: { connectedSupplier: {}, supplierInfo: {} },
  getters: {
    supplierConnected: (state) => state.connectedSupplier,
    supplierInfo: (state) => state.supplierInfo,
  },
  actions: {
    async getConnectedSupplier({ dispatch, commit }) {
      try {
        const response = await AuthService.getInitialState();
        if (response.status === 200) {
          commit("SET_CONNECTED_SUPPLIER", response.data);
          return true;
        } else {
          dispatch("alert/error", response.data.message, {
            root: true,
          });
          return false;
        }
      } catch (error) {
        dispatch("alert/error", "Something went wrong. Try again later", {
          root: true,
        });
      }
    },
    async getSupplierInformation({ dispatch, commit }) {
      try {
        const response = await SupplierSettingService.getSupplierDetail();

        if (response.status === 200) {
          commit("SET_SUPPLIER_INFO", response.data);
          return true;
        } else {
          dispatch("alert/error", response.data.message, {
            root: true,
          });
          return false;
        }
      } catch (error) {
        console.log("get Supplier Information error:", error);
        dispatch("alert/error", "Something went wrong. Try again later", {
          root: true,
        });
      }
    },
    async updateSupplierInformation({ dispatch, commit }, payload) {
      try {
        const response = await SupplierSettingService.updategetSupplierDetail(
          payload
        );
        if (response.status === 200) {
          commit("SET_SUPPLIER_INFO", payload);
          return true;
        } else {
          dispatch("alert/error", response.data.message, {
            root: true,
          });
          return false;
        }
      } catch (error) {
        dispatch("alert/error", "Something went wrong. Try again later", {
          root: true,
        });
      }
    },
  },
  mutations: {
    SET_CONNECTED_SUPPLIER(state, payload) {
      state.connectedSupplier = payload;
    },
    SET_SUPPLIER_INFO(state, payload) {
      state.supplierInfo = payload;
    },
  },
};
