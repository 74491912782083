import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { global } from "./global.module";
import { alert } from "./alert.module";
import { retailer } from "./retailer.module";
import { supplier } from "./supplier.module";
import { wizard } from "./wizard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    global,
    auth,
    retailer,
    supplier,
    wizard,
  },
});
